import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Grid, TextField, Box, Chip, FormControl, InputLabel, MenuItem, Select, Button, Typography, Divider } from '@mui/material';

// Define an interface for the props
interface ServiceReportModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  title?: string;
}

// Ensure the app element is set
Modal.setAppElement('#root');

// Define the component with typed props
const ServiceReportModal: React.FC<ServiceReportModalProps> = ({
  isOpen,
  onRequestClose,
  title = 'Field Service Report'
}) => {

  const [selectedReport, setSelectedReport] = useState('');
  const [selectedTech, setSelectedTech] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // Get the first day of the current month
    const getFirstDayOfMonth = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      const firstDay = new Date(year, month, 1);
      return firstDay.toISOString().split('T')[0]; // Format yyyy-mm-dd
    };

    // Get the current date
    const getCurrentDate = () => {
      const today = new Date();
      return today.toISOString().split('T')[0]; // Format yyyy-mm-dd
    };

    // Set the start date as the first day of the month and end date as today
    setStartDate(getFirstDayOfMonth());
    setEndDate(getCurrentDate());

  }, []);

  const handleReportChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSelectedReport(event.target.value);
  };

  const handleTechnicianChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSelectedTech(event.target.value);
  };

  const handleStartDateChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setEndDate(event.target.value);
  };

  const [result, setResult] = useState(""); // State to hold the result

  const handleGetClick = () => {
    const fetchedResult = "Results fetched based on the selected Report, Start Date, and End Date.";
    setResult(fetchedResult);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
        },
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Chip
          label={<span style={{ fontWeight: 'normal', display: 'block', textAlign: 'left' }}>{title.split('').map((char, index) => (
            <span key={index}>{char.toUpperCase()}&nbsp;</span>
          ))}</span>}
          color="secondary"
          style={{ marginBottom: '10px', width: '100%', borderRadius: 4 }}
        />
      </div>
      <Box sx={{ width: '100%', flewGrow: 1 }}>
        <Grid container spacing={1}>
          {/* Report Name Dropdown with 50% width */}
          <Grid item sm={6}>
            <FormControl fullWidth>
              <InputLabel id="report-select-label">Select a Report</InputLabel>
              <Select
                labelId="report-select-label"
                value={selectedReport}
                label="Select a Report"
                onChange={handleReportChange}
              >
                {/* Daily Reports */}
                <MenuItem value="dailyJobSummary">Daily Job Summary Report</MenuItem>
                <MenuItem value="workOrderCompletion">Work Order Completion Report</MenuItem>
                <MenuItem value="serviceCallLog">Service Call Log</MenuItem>
                <MenuItem value="equipmentService">Equipment Service Report</MenuItem>
                <MenuItem value="partsUsage">Parts Usage Report</MenuItem>
                <MenuItem value="pendingIssues">Pending/Unresolved Issues Report</MenuItem>
                <MenuItem value="partsUsage">Call Aging Report</MenuItem>

                {/* Monthly Reports */}
                <MenuItem value="monthlyServicePerformance">Monthly Service Performance Report</MenuItem>
                <MenuItem value="maintenanceScheduleAdherence">Maintenance Schedule Adherence Report</MenuItem>
                <MenuItem value="partsInventoryUsage">Parts Inventory Usage Report</MenuItem>
                <MenuItem value="equipmentFailureAnalysis">Equipment Failure/Repair Analysis Report</MenuItem>
                <MenuItem value="workOrderEfficiency">Work Order Efficiency Report</MenuItem>
              </Select>

            </FormControl>
          </Grid>
          {/* Start and End Dates with combined 50% width */}
          <Grid item sm={3}>
            <FormControl fullWidth size="small">
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl fullWidth size="small">
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl fullWidth>
              <InputLabel id="report-select-label">Technician</InputLabel>
              <Select
                labelId="report-select-label"
                value={selectedTech}
                label="Technician"
                onChange={handleTechnicianChange}
              >
                <MenuItem value="tech0">All-Technicians</MenuItem>
              </Select>
            </FormControl>

          </Grid>
          <Grid item sm={3} container alignItems="center" justifyContent="center" >
            <Button
              variant="contained"
              color="primary"
              title="Click to generate report"
              style={{ minWidth: "100px", borderRadius: 50 }} // Set a fixed minWidth for button size consistency
              onClick={handleGetClick} // Handle button click
            >
              Get
            </Button>
          </Grid>
        </Grid>
        {/* New Grid for Results */}
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            {result && ( // Conditional rendering for result
              <Typography variant="h6">
                {result}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container direction="column">
          {/* Horizontal Line */}
          <Grid item>
            <Divider />
          </Grid>

          {/* Button Row */}
          <Grid item container justifyContent="flex-end" sx={{ marginTop: '8px' }}>
            <Grid item>
              <Button
                onClick={onRequestClose}
                variant="contained"
                color="warning"
                sx={{
                  marginLeft: '10px',
                  width: '80px',
                  borderRadius: '50px', // Use a higher border radius for rounded corners
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ServiceReportModal;
