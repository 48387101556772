import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { FaEdit } from 'react-icons/fa';
import { CSVLink } from 'react-csv'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { useDataProvider } from '../../hooks/useDataProvider';
import {
  CLOSE_STATUS_CHECK,
  SERVICE_CALL_STATUS_ENUM,
  ServiceCall,
  emptyServiceCall,
  serviceCallDivisionDescription,
  serviceCallStatusDescription,
  serviceCallTypeDescription
} from '../../models/ServiceCall';
import ServiceCallDialog, { serviceCallModalOpen } from './ServiceCallDialog';
import AssignedCallsDialog from './AssignedCallsDialog';
import PrintCallModal from '../shared/PrintCallModal';
import { forEach } from 'lodash';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DownloadIcon from '@mui/icons-material/Download';
import ServiceReportModal from '../../components/ServiceReportModal';

function Dashboard() {
  const data = useDataProvider();

  const pending = SERVICE_CALL_STATUS_ENUM.indexOf("Pending");
  const in_transit = SERVICE_CALL_STATUS_ENUM.indexOf("In_Transit");
  const on_site = SERVICE_CALL_STATUS_ENUM.indexOf("On_Site");
  const hold_parts = SERVICE_CALL_STATUS_ENUM.indexOf("Hold_Parts");
  const parts_available = SERVICE_CALL_STATUS_ENUM.indexOf("Parts_Available");
  const parts_warranty = SERVICE_CALL_STATUS_ENUM.indexOf("Parts_Warranty");
  const closed_completed = SERVICE_CALL_STATUS_ENUM.indexOf("Closed_Completed");
  const cancelled = SERVICE_CALL_STATUS_ENUM.indexOf("Cancelled");
  const need_more_time = SERVICE_CALL_STATUS_ENUM.indexOf("Need_More_Time");
  const tech_assist = SERVICE_CALL_STATUS_ENUM.indexOf("Tech_Assist");
  const in_shop_call = SERVICE_CALL_STATUS_ENUM.indexOf("In_Shop_Call");
  const hold_supplies = SERVICE_CALL_STATUS_ENUM.indexOf("Hold_Supplies");
  const waiting_for_approval = SERVICE_CALL_STATUS_ENUM.indexOf("Waiting_For_Approval");
  const costs_approved = SERVICE_CALL_STATUS_ENUM.indexOf("Costs_Approved");
  const parts_processing = SERVICE_CALL_STATUS_ENUM.indexOf("Parts_Processing");

  const defaultStatuses = [...Array.from(Array(SERVICE_CALL_STATUS_ENUM.length).keys())].filter(x => !CLOSE_STATUS_CHECK(x));
  const closedStatuses = [...Array.from(Array(SERVICE_CALL_STATUS_ENUM.length).keys())].filter(x => CLOSE_STATUS_CHECK(x));
  // const partsStatuses = [4, 6, 7, 14, 15, 16]
  const partsStatuses = [hold_parts,
    parts_available,
    parts_warranty,
    waiting_for_approval,
    costs_approved,
    parts_processing]

  const [machineIdFilter, setmachineIdFilter] = useState('');
  const [statusFilters, setStatusFilter] = useState<number[]>(defaultStatuses);
  const [statusCounts, setStatusCounts] = useState<number[]>([]);
  const [serialFilter, setSerialFilter] = useState('');
  const [modelFilter, setModelFilter] = useState('');
  const [customerFilter, setCustomerFilter] = useState('');
  const [techFilter, setTechFilter] = useState<(string | undefined)[]>([]);
  const [workOrderNumberFilter, setWorkOrderNumberFilter] = useState('')

  const [startDate, setStartDate] = useState(data.callsFilter?.start)
  const [endDate, setEndDate] = useState(data.callsFilter?.end)
  const beginningoffss = moment().year(2023).month(0).date(1).format('YYYY-MM-DD') //months are zero indexed
  const todaysDate = moment().format('YYYY-MM-DD')

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const [serviceReportModalIsOpen, setServiceReportModalIsOpen] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const techs = useMemo(() => {
    return data.users?.filter(u => u.access_level === 0 && u.active);
  }, [data]);

  const handleStatusFilterChange = (event: SelectChangeEvent<typeof statusFilters>) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? [parseInt(value)] : value
    );
  };

  interface BreakDown {
    tech: string | undefined;
    tasks: ServiceCall[];
  }

  const svclist = useMemo(() => {
    let res = data.formattedServiceCalls?.slice(); // create shallow copy

    if (machineIdFilter !== '') {
      res = res?.filter(r => {
        if (r.machine?.external_id === undefined)
          return false
        return r.machine?.external_id === machineIdFilter
      })
    }

    if (techFilter.length > 0) {
      res = res?.filter(r => {
        // if(r.assigned_to?.id === undefined)
        //   return false
        return techFilter.includes(r.assigned_to?.id as string)
      })
    }

    if (statusFilters.length > 0) {
      res = res?.filter(c => {
        if (c.call.status === undefined)
          return false
        return statusFilters.includes(c.call.status)
      })
    }

    if (serialFilter !== '') {
      const lfilter = serialFilter.toLowerCase()

      res = res?.filter(c => {
        if (c.machine?.serial === undefined)
          return false
        return c.machine?.serial.toLowerCase().includes(lfilter)
      })
    }

    if (modelFilter !== '') {
      const lfilter = modelFilter.toLowerCase()

      res = res?.filter(c => {
        if (c.machine?.model === undefined)
          return false
        return c.machine?.model.toLowerCase().includes(lfilter)
      })
    }

    if (customerFilter !== '') {
      const lfilter = customerFilter.toLowerCase()

      res = res?.filter(c => {
        if (c.customer?.name === undefined)
          return false
        return c.customer?.name.toLowerCase().includes(lfilter)
      })
    }

    if (workOrderNumberFilter !== '') {
      const lowerCaseWorkOrderNumberFilter = workOrderNumberFilter.toLowerCase()

      res = res?.filter(c => {
        if (c.call.external_id === undefined)
          return false
        return c.call.external_id?.toLowerCase().includes(lowerCaseWorkOrderNumberFilter)
      })
    }

    const val = res ?? []
    return val;
  }, [data, machineIdFilter, statusFilters, techFilter, serialFilter, modelFilter, customerFilter, workOrderNumberFilter])

  const csvData = useMemo(() => {
    return svclist.map(s => {
      return {
        wo: s.call.external_id,
        division: serviceCallDivisionDescription(s.call.division as number),
        call_type: serviceCallTypeDescription(s.call.call_type as number),
        assigned_to: s.assigned_to?.name || 'unassigned',
        machine: `Model: ${s.machine?.model}) ID#: ${s.machine?.external_id}`,
        machine_location: `${s.machine?.address}, ${s.machine?.city}, ${s.machine?.state}`,
        customer: s.customer?.name,
        status: serviceCallStatusDescription(s.call.status),
        description: s.description?.trim().replace(/(\r\n|\n|\r)/gm, "|"),
        contract: s.machine?.contract
      }
    }).sort((a, b) => (a.assigned_to > b.assigned_to) ? 1 : ((b.assigned_to > a.assigned_to) ? -1 : 0))
  }, [svclist])

  const breakDown: BreakDown[] = useMemo(() => {
    const map = new Map<(string | undefined), ServiceCall[]>();

    techs?.forEach(t => map.set(t.id as string, []))
    map.set(undefined, []) // unassigned calls

    svclist?.forEach(fs => {
      const tech = fs.assigned_to?.id as string;
      const assigned = map.get(tech) ?? [];
      assigned.push(fs.call);
      map.set(tech, assigned);
    })

    let res: BreakDown[] = [];
    map.forEach((v, k) => {
      res.push({ tech: k, tasks: v })
    })
    return res;
  }, [svclist, techs])

  // update counts whenever svclist changes
  useEffect(() => {
    let counts = new Array(SERVICE_CALL_STATUS_ENUM.length).fill(0)

    SERVICE_CALL_STATUS_ENUM.forEach((ss, i) => {
      counts[i] = svclist.filter(sc => sc.status === ss).length
    })

    setStatusCounts(counts)
  }, [svclist])

  return (
    <>
      {/* <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Service Calls
      </Typography> */}
      {/*alvin modification*/}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Service Calls
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" gap={1} justifyContent="flex-end">
            <Button

              onClick={(e) => {
                serviceCallModalOpen(emptyServiceCall());
              }}
              variant="contained"
              style={{ minWidth: "100px", borderRadius: 50 }} // Set a fixed minWidth for button size consistency
              startIcon={<AddIcCallIcon />}
            >
              Add Call
            </Button>
            <Button
              color='secondary'
              title="Click to view tech report"
              onClick={(e) => {
                setServiceReportModalIsOpen(true);
              }}
              variant="contained"
              style={{ minWidth: "100px", borderRadius: 50 }} // Set a fixed minWidth for button size consistency
              startIcon={<SummarizeIcon />}
            >
              Report
            </Button>
            <CSVLink
              style={{
                textAlign: "center",
                display: "inline-block",
                padding: "6px 16px",
                background: "green",
                color: "white",
                borderRadius: 50,
                textDecoration: "none",
                minWidth: "100px", // Same size as Add Call button
              }}
              filename="SVC_CALL_EXPORT.csv"
              data={csvData}
            >
              <DownloadIcon style={{ marginRight: "8px", verticalAlign: "middle" }} />
              Export
            </CSVLink>
          </Box>
        </Grid>
      </Grid>


      <Grid container sx={{ mt: 2, mb: 2 }}>
        {breakDown.map((bd, i) => (
          <Grid key={`bd${i}`} item sm={12} md={4}>
            {data.users?.find(u => u.id === bd.tech)?.name.trim() && <Paper sx={{ p: 1, m: 1 }} elevation={3}>
              <Grid container>
                <Grid item sm={8}>
                  <Button
                    variant={techFilter.includes(bd.tech) ? "contained" : "outlined"}
                    title="Click to view"
                    color={techFilter.includes(bd.tech) ? "primary" : "info"}
                    // sx={{ width: "200px"}}
                    style={{ textAlign: "left", borderRadius: 50 }}
                    startIcon={<AccountCircleIcon fontSize='large' />}
                    onClick={(e) => {
                      //assignedCallsModalOpen(bd.tech, bd.tasks);

                      // toggle techs in techFilter
                      // let techname = bd.tech === undefined ? null : bd.tech;
                      if (!techFilter.includes(bd.tech)) {
                        setTechFilter([...techFilter, bd.tech /* techname */]);
                      } else {
                        setTechFilter([...techFilter.filter(tf => tf !== bd.tech /* techname */)]);
                      }
                    }}
                  >
                    {data.users?.find(u => u.id === bd.tech)?.name}
                  </Button>
                </Grid>
                <Grid item sm={2}>
                  <Chip label={bd.tasks.filter(sc => sc.status === pending).length} variant="outlined"/>
                </Grid>
                <Grid item sm={2}>
                  <Chip label={bd.tasks.length}
                    style={{ color: "green", fontWeight: 'bold' }} variant="outlined"
                  />
                </Grid>
              </Grid>
            </Paper>}
          </Grid>
        ))}
      </Grid>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        <Grid container>
          <Grid item sm={7}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><b>Filters</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        data.setCallsFilter({
                          ...data.callsFilter,
                          start: e.target.value,
                        });
                      }}
                      type="date"
                      label="Start Date"
                      value={startDate}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        data.setCallsFilter({
                          ...data.callsFilter,
                          end: e.target.value,
                        });
                      }}
                      type="date"
                      label="End Date"
                      value={endDate}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Button
                      disabled={data.serviceCallsLoading}
                      onClick={() => {
                        data.fetchServiceCalls();
                      }}
                      fullWidth
                      variant="contained"
                    >
                      Apply
                    </Button>
                  </Grid>
                  <Grid item sm={12}>
                    <hr />
                    <h2><b>Search by:</b></h2>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      name="textfilter"
                      value={workOrderNumberFilter}
                      label="Work Order Number"
                      onChange={(e) => {
                        setWorkOrderNumberFilter(e.target.value);
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      name="textfilter"
                      value={modelFilter}
                      label="Printer Model"
                      onChange={(e) => {
                        setModelFilter(e.target.value);
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      name="textfilter"
                      value={serialFilter}
                      label="Serial Number"
                      onChange={(e) => {
                        setSerialFilter(e.target.value);
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      name="textfilter"
                      value={customerFilter}
                      label="Customer Name"
                      onChange={(e) => {
                        setCustomerFilter(e.target.value);
                      }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      name="filter"
                      value={machineIdFilter}
                      label="Equipment Id"
                      onChange={(e) => {
                        setmachineIdFilter(e.target.value);
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Statuses
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={statusFilters}
                        onChange={handleStatusFilterChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) =>
                          selected.map((s) => serviceCallStatusDescription(s))
                            .join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {SERVICE_CALL_STATUS_ENUM.map((name, i) => (
                          <MenuItem key={name} value={i}>
                            <Checkbox checked={statusFilters.indexOf(i) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <hr />
                    <h2><b>Quick Filters</b></h2>
                    <Button
                      sx={{ mt: 1, ml: 1 }}
                      onClick={(e) => {
                        setStatusFilter([]);
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      All Calls
                    </Button>
                    <Button
                      sx={{ mt: 1, ml: 1 }}
                      onClick={(e) => {
                        setStatusFilter(defaultStatuses);
                      }}
                      variant="contained"
                      color='secondary'
                    >
                      Current Calls
                    </Button>
                    <Button
                      sx={{ mt: 1, ml: 1 }}
                      onClick={(e) => {
                        setStatusFilter(closedStatuses);
                      }}
                      variant="contained"
                      color='secondary'
                    >
                      Closed Calls
                    </Button>
                    <Button
                      sx={{ mt: 1, ml: 1 }}
                      onClick={(e) => {
                        setStatusFilter(partsStatuses);
                      }}
                      variant="contained"
                      color='secondary'
                    >
                      Parts
                    </Button>
                  </Grid>
                  <Grid item sm={12}>
                    <hr />
                  </Grid>
                  <Grid item sm={12}>
                    <Button
                      sx={{ mt: 1 }}
                      onClick={(e) => {
                        setmachineIdFilter("");
                        setStatusFilter(defaultStatuses);
                        setSerialFilter("");
                        setModelFilter("");
                        setCustomerFilter("");
                        setWorkOrderNumberFilter("");
                        setTechFilter([]);
                        setStartDate(beginningoffss);
                        setEndDate(todaysDate);
                      }}
                      variant="contained"
                    >
                      Reset All Filters
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* <Grid item sm={3}> */}
          <Grid item sm={5}>
            <Box ml={1.25}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography><b>Status Breakdown</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {SERVICE_CALL_STATUS_ENUM.map((ss, i) => (
                    (statusCounts[i] > 0 && <div><b><i>{ss}</i></b>: {statusCounts[i]}</div>)
                  ))}
                  <div><i>Total: </i>{statusCounts.reduce((acc, current) => acc + current, 0)}</div>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          {/* <Grid item sm={2}>
            <Button
              onClick={(e) => {
                serviceCallModalOpen(emptyServiceCall());
              }}
              variant="contained"
              fullWidth
            >
              Add Call
            </Button>
            <CSVLink
              style={{
                textAlign: "center",
                display: "inline-block",
                width: "100%",
                marginTop: "10px",
                background: "green",
                color: "white",
                borderRadius: "3px",
              }}
              filename="SVC_CALL_EXPORT.csv"
              data={csvData}
            >
              Export
            </CSVLink>
          </Grid> */}
        </Grid>
      </Typography>

      {/*Service Report Modal*/}
      <ServiceReportModal
        isOpen={serviceReportModalIsOpen}
        onRequestClose={() => setServiceReportModalIsOpen(false)} 
        title='Field Service Report'
      />

      <hr style={{ margin: "1rem" }} />

      {data.customersLoading &&
        data.machinesLoading &&
        data.serviceCallsLoading &&
        data.usersLoading && <>Loading...</>}

      {!data.customersLoading &&
        !data.machinesLoading &&
        !data.serviceCallsLoading &&
        !data.usersLoading && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Call Data</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Customer / Machine info</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {svclist.length < 1 && (
                  <>
                    <TableRow>
                      <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                        - No Data -
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {svclist.slice(0).reverse().map((dr) => (
                  <TableRow key={dr.id}>
                    <TableCell>
                      <Chip
                        size="small"
                        color="success"
                        label={serviceCallDivisionDescription(dr.call.division)}
                      />
                      {dr.call.is_callback && (
                        <Chip
                          sx={{ ml: 1 }}
                          size="small"
                          color="warning"
                          label="Callback"
                        />
                      )}
                      {dr.customer?.hold && (
                        <Chip
                          sx={{ ml: 1 }}
                          size="small"
                          color="error"
                          label="On Hold"
                        />
                      )}
                      {dr.call?.vip && (
                        <Chip
                          sx={{ ml: 1 }}
                          size="small"
                          color="secondary"
                          label="VIP"
                        />
                      )}
                      <div>
                        <b>ID:</b>
                        <span style={{ fontFamily: "monospace" }}>
                          {" "}
                          {dr.call.external_id || dr.id}
                        </span>
                      </div>
                      <div>
                        <b>Created:</b>{" "}
                        <span style={{ fontFamily: "monospace" }}>
                          {dr.created} ({dr.time_since_creation})
                        </span>
                      </div>
                      <div>
                        <b>Created By:</b>{" "}
                        <span style={{ fontFamily: "monospace" }}>
                          {dr.created_by_name}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {dr.assigned_to?.name ?? "---"}
                    </TableCell>
                    <TableCell>
                      <div>
                        {dr.machine?.contract && (
                          <Chip
                            sx={{ ml: 1 }}
                            size="small"
                            color="warning"
                            label="Contract"
                          />
                        )}
                      </div>
                      <div>
                        <u><i><b>{dr.customer?.name}</b></i></u>
                        <br />
                        <b>Call Type: </b>
                        <span
                          style={{
                            color: dr.call.call_type === 3 ? "red" : "inherit",
                            fontWeight:
                              dr.call.call_type === 3 ? "bold" : "inherit",
                          }}
                        >
                          {serviceCallTypeDescription(dr.call.call_type ?? -1)}
                        </span>
                      </div>
                      <div>
                        <b>Serial Number: </b>
                        <span style={{ fontFamily: "monospace" }}>
                          {dr.machine?.serial !== null && dr.machine?.serial !== undefined && dr.machine?.serial !== "" ? (
                            dr.machine?.serial
                          ) : (
                            <span style={{ color: "red", fontWeight: "bold" }}>N/A</span>
                          )}
                        </span>
                      </div>
                      <div>
                        <b>Machine:</b>{" "}
                        <span style={{ fontFamily: "monospace" }}>
                          {dr.machine?.model} {(dr.machine?.external_id !== undefined && dr.machine?.external_id !== "") &&
                            <div>
                              <u><i>Equipment ID:</i></u> {" "}
                              <span style={{ fontFamily: "monospace" }}>
                                {dr.machine?.external_id}
                              </span>
                            </div>}
                          {/* <br /> */}
                          {dr.machine?.brand} {dr.machine?.make}
                        </span>
                      </div>
                      <div>
                        <b style={{ display: "block" }}>Description:</b>
                        <span
                          style={{
                            fontFamily: "monospace",
                            backgroundColor: "#eee",
                            padding: "5px",
                          }}
                        >
                          {dr.description}
                        </span>
                      </div>
                      <div>
                        <b>Machine Location: </b>
                        {dr.machine?.address}, {dr.machine?.city},{" "}
                        {dr.machine?.state}, {dr.machine?.zip}
                      </div>
                    </TableCell>
                    <TableCell>
                      <span style={{
                        color: moment().diff(moment(dr.call?.created), 'days') >= 10 ? "red" : "inherit" //mark pending calls 10 days or older
                      }}>
                        {dr.status}
                        <br />
                        {dr.time_since_last_update}
                      </span>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          serviceCallModalOpen(dr.call);
                        }}
                      >
                        <FaEdit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

      <ServiceCallDialog onClose={data.fetchServiceCalls} />
      {/* <PrintCallModal onClose={data.fetchServiceCalls} /> */}
      <AssignedCallsDialog svcCallOpener={serviceCallModalOpen} />
    </>
  );
}

export default Dashboard